.section1-header {
  @include media-breakpoint-up(xs) {
    font-size: 32px !important;
  }  
  @include media-breakpoint-up(md) {
    font-size: 35px !important;
  }  
  @include media-breakpoint-up(lg) {
    font-size: 45px !important;
  }    
}

.choice-section-title {
  @include media-breakpoint-up(xs) {
    font-size: 32px !important;
  }  
  @include media-breakpoint-up(md) {
    font-size: 35px !important;
  }  
  @include media-breakpoint-up(lg) {
    font-size: 48px !important;
  }      
}

.provider-selector {
  @include media-breakpoint-up(xs) {
    width: 100% !important;
  }  
  @include media-breakpoint-up(md) {
    width: 33% !important;
  }  
  @include media-breakpoint-up(lg) {
    width: 25% !important;
  }       
}

.w-80 {
  width: 80% !important;  
}

.section1-container {
  @include media-breakpoint-up(xs) {
    padding-top: 50px !important;
  }  
  @include media-breakpoint-up(md) {
    padding-top: 75px !important;
  }  
  @include media-breakpoint-up(lg) {
    padding-top: 100px !important;
  }       
}